import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import styles from './politique.module.scss'

class Politique extends React.Component {
  render() {
    const siteTitle = 'L\'Atelier Info | Politique de confidentialité !'
    const pageTitle = 'Politique de confidentialité !'
    return (
      <Layout location={this.props.location}>
        <div className="page-wrapper">
          <Helmet title={siteTitle} />
          <div className="container">
            <section className={styles.politique}>
            <h1>{pageTitle}</h1>
            
            <div><strong>Introduction</strong></div>
            <div>Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée. C’est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.</div>
            
            <h2>Collecte des renseignements personnels</h2>
            
            <div>
            <ul>
                <li>Prénom</li>
                <li>Adresse postale</li>
                <li>Code postal</li>
                <li>Adresse électronique</li>
                <li>Numéro de téléphone / télécopieur</li>
            </ul>
            </div>
            <div>Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l’interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations vous concernant.</div>
            
            <h2>Formulaires&nbsp;et interactivité:</h2>
            <div>Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :</div>
            
            <div>
            <ul>
                <li>Formulaire d'inscription au site Web</li>
                <li>Formulaire de commande</li>
            </ul>
            </div>
            <div>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :</div>
            
            <div>
            <ul>
                <li>Suivi de la commande</li>
                <li>Informations / Offres promotionnelles</li>
                <li>Statistiques</li>
                <li>Contact</li>
            </ul>
            </div>
            <div>Vos renseignements sont également collectés par le biais de l’interactivité pouvant s’établir entre vous et notre site Web et ce, de la façon suivante:</div>
            
            <div>
            <ul>
                <li>Commentaires </li>
                <li>Correspondance</li>
            </ul>
            </div>
            <div>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :</div>
            
            <div>
            <ul>
                <li>Informations ou pour des offres promotionnelles</li>
                <li>Statistiques</li>
                <li>Contact </li>
            </ul>
            </div>
            
            <h2>Droit d’opposition et de retrait</h2>
            <div>Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements personnels.</div>
            <div>Le droit d’opposition s’entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte.</div>
            
            <div>Le droit de retrait s’entend comme étant la possiblité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.</div>
            
            <div><strong>Pour pouvoir exercer ces droits, vous pouvez :</strong></div>
            
            <div>Code postal :&nbsp; J3L 6Z7</div>
            <div>Courriel :&nbsp; contact@atelierinfo.com</div>
            <div>Téléphone :&nbsp; 450-658-5005</div>
            <div>Section du site web :&nbsp;&nbsp; https://www.latelierinfo.com/contact/</div>
            
            <h2>Droit d’accès</h2>
            <div>Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant.</div>
            
            <div><strong>L’exercice de ce droit se fera :</strong></div>
            
            <div>Code postal :&nbsp; J3L 6Z7</div>
            <div>Courriel :&nbsp; contact@lashopinfo.com</div>
            <div>Téléphone :&nbsp; 450-658-5005</div>
            <div>Section du site web :&nbsp;&nbsp; https://www.latelierinfo.com/contact/</div>
            
            <h2>Sécurité</h2>
            <div>Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.</div>
            
            <div>Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :</div>
            
            <div>
            <ul>
                <li>Protocole SSL</li>
                <li>Sauvegarde informatique</li>
                <li>Identifiant / mot de passe</li>
                <li>Pare-feu</li>
            </ul>
            </div>
            <div>Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsque l’on utilise Internet pour transmettre des renseignements personnels.</div>
            
            <h2>Législation</h2>
            <div>Nous nous engageons à respecter les dispositions législatives énoncées dans :</div>
            
            <div>La Charte canadienne des droits et libertés</div>
            
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Politique

